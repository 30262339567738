import React, { useState, useRef } from "react"

import PrideNavHomepage from "components/Navbars/PrideNavHomepage"
import { Col, Container, Row } from "reactstrap"

import { useHistory, Link } from "react-router-dom"

import Slider from "react-slick"

import { ChevronDown, ChevronLeft, ChevronRight, Download } from "react-feather"

import scrollToComponent from "react-scroll-to-component"

import "./Sabermais.scss"

import AddTherapist from "../modals/AddTherapist.jsx"

export default function Sabermais() {
  const [showModalTherapist, updateShowModalTherapist] = useState(false)

  const history = useHistory()

  const sobrePrideRef = useRef()
  const perturbaçõesRef = useRef()

  const perturbaçõesSlideItems = [
    {
      title: "Anorexia Nervosa",
      text: "É caracterizada por uma extrema restrição alimentar, perda de peso significativa e por um medo intenso de ganhar peso."
    },
    {
      title: "Perturbação da Alimentação e da Ingestão com outra Especificação",
      text: "É o termo utilizado para descrever uma perturbação alimentar que tem um impacto significativo na vida do indivíduo, mas que não cumpre todos os critérios definidos para nenhuma das outras perturbações alimentares."
    },
    {
      title: "Perturbação Alimentar Restritiva/ Evitante",
      text: "É descrita como um problema na alimentação (tais como evitamento das caraterísticas sensoriais do alimento como o cheiro, aspeto e textura; falta de apetite; preocupação acerca das consequências aversivas da comida), que resulta na falha em alcançar os valores nutricionais e energéticos necessários."
    },
    {
      title: "Anorexia Nervosa",
      text: "É caracterizada por uma extrema restrição alimentar, perda de peso significativa e por um medo intenso de ganhar peso."
    },
    {
      title: "Perturbação da Alimentação e da Ingestão com outra Especificação",
      text: "É o termo utilizado para descrever uma perturbação alimentar que tem um impacto significativo na vida do indivíduo, mas que não cumpre todos os critérios definidos para nenhuma das outras perturbações alimentares."
    },
    {
      title: "Perturbação Alimentar Restritiva/ Evitante",
      text: "É descrita como um problema na alimentação (tais como evitamento das caraterísticas sensoriais do alimento como o cheiro, aspeto e textura; falta de apetite; preocupação acerca das consequências aversivas da comida), que resulta na falha em alcançar os valores nutricionais e energéticos necessários."
    }
  ]

  const singleSlideItems = [
    {
      title:
        "“Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa.”"
    },
    {
      title:
        "“Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa.”"
    },
    {
      title:
        "“Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa.”"
    },
    {
      title:
        "“Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa.”"
    },
    {
      title:
        "“Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa.”"
    },
    {
      title:
        "“Os sintomas associados às Perturbações Alimentares envolvem o controlo rígido da comida, peso e forma corporal, na tentativa de controlar sentimentos ou dificuldades psicológicas subjacentes. No entanto, estes comportamentos resultam exatamente no oposto: sentimentos de desesperança e avaliação negativa.”"
    }
  ]

  function scrollSection(section) {
    history.push("/")
    scrollToComponent(this[section], {
      offset: -70,
      align: "top",
      duration: 500,
      ease: "linear"
    })
  }

  return (
    <>
      <AddTherapist
        visible={showModalTherapist}
        toggleModal={() => updateShowModalTherapist(!showModalTherapist)}
      />
      <div className="sabermais">
        <div className="hero">
          <PrideNavHomepage
            scrollSection={scrollSection}
            showMenus={true}
            addTherapist={() => updateShowModalTherapist(!showModalTherapist)}
          />

          <Container fluid>
            <Row>
              <Col className="new-bg-orange hero-col">
                <img
                  className="top-left"
                  src={require("assets/img/shapes/shape-white-2-300.png")}
                  alt="shape-white"
                  style={{ top: "30px" }}
                />
                <div>
                  <p
                    className="mb-2 text-left text-header-stroke text-stroke-white"
                    style={{ paddingBottom: "30px" }}
                  >
                    PROJECTO PRIDE
                  </p>

                  <span className="hero-text">
                    O PRIDE é um projeto desenvolvido pelo Grupo de Estudos das
                    Perturbações Alimentares da Escola de Psicologia da
                    Universidade do Minho
                  </span>

                  <button
                    onClick={() =>
                      scrollToComponent(sobrePrideRef.current, {
                        offset: -70,
                        align: "top",
                        duration: 500,
                        ease: "linear"
                      })
                    }
                  >
                    <img
                      src={require("assets/img/buttons/right-white@2x.png")}
                      alt="right-icon"
                    />

                    <span>Saber +</span>
                  </button>
                </div>
              </Col>
              <Col className="new-bg-red hero-col">
                <img
                  className="right-bottom"
                  src={require("assets/img/shapes/shape-white-2-300.png")}
                  alt="shape-white"
                  style={{ transform: "rotate(180deg)" }}
                />
                <div>
                  <div className="mb-2" style={{ paddingBottom: "30px" }}>
                    <p className="m-b-0 text-left text-uppercase text-header-stroke text-stroke-white">
                      Perturbações
                    </p>

                    <p className="m-b-0 text-left text-uppercase text-header-stroke text-stroke-white">
                      Alimentares
                    </p>
                  </div>

                  <span className="hero-text">
                    As Perturbações Alimentares são caracterizadas por
                    alterações persistentes no padrão alimentar ou no
                    comportamento associado à alimentação, comprometendo de
                    forma significativa a saúde física e o funcionamento
                    psicossocial
                  </span>

                  <button
                    onClick={() =>
                      scrollToComponent(perturbaçõesRef.current, {
                        offset: -70,
                        align: "top",
                        duration: 500,
                        ease: "linear"
                      })
                    }
                  >
                    <img
                      src={require("assets/img/buttons/right-white@2x.png")}
                      alt="right-icon"
                    />
                    <span>Saber +</span>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="sobre-pride" ref={sobrePrideRef}>
          <p className="text-header-stroke text-stroke-orange m-b-0 text-uppercase">
            Sobre o PRIDE
          </p>

          <Container className="p-0 mt-3" fluid>
            <Row>
              <Col>
                <span>
                  O PRIDE (Personalised Research Informed Interventions for
                  Disordered Eating) é um projeto desenvolvido pelo Grupo de
                  Estudos das Perturbações Alimentares (GEPA) da Escola de
                  Psicologia da Universidade do Minho.
                </span>

                <div className="mt-5">
                  <h3>A Missão PRIDE</h3>

                  <span className="mt-4">
                    Assentes nos princípios do rigor clínico, entusiamo pela
                    procura de conhecimento baseado em evidência, e na
                    colaboração entre investigadores, cuidadores e profissionais
                    de saúde, pretendemos contribuir para o desenvolvimento do
                    conhecimento na área das perturbações alimentares, na sua
                    prevenção, diagnóstico e tratamento.
                  </span>
                </div>

                <div className="mt-5">
                  <h3>Valores PRIDE</h3>

                  <span className="mt-4 ">
                    Colaboração e partilha • Excelência e rigor • Inovação •
                    Respeito e integridade • Dedicação e Compromisso
                  </span>
                  <br />
                  <br />

                  <Link
                    to={{
                      pathname: "/",
                      state: {
                        hash: "newsletter"
                      }
                    }}
                    className="new-bg-orange text-white p-2 rounded"
                    style={{ letterSpacing: "1px", cursor: "pointer" }}
                  >
                    Saiba + Como Apoiar
                  </Link>
                </div>
              </Col>
              <Col className="pilares">
                <h3>Os pilares de atuação do PRIDE:</h3>

                <div className="new-bg-orange text-white rounded mb-4 pilares">
                  <span className="font-weight-bold">
                    Investigação Científica •
                  </span>{" "}
                  Desenvolver e disseminar investigação inovadora e relevante
                  para informar a prática clínica dos profissionais de saúde
                  mental.
                </div>

                <div className="new-bg-orange text-white rounded mb-4 pilares">
                  <span className="font-weight-bold">
                    Intervenção Clínica •
                  </span>{" "}
                  Disponibilizar o melhor tratamento clínico para pessoas com
                  problemas do comportamento alimentar, procurando diagnósticos
                  precoces e a oferta de tratamentos e práticas baseadas na
                  evidência científica.
                </div>

                <div className="new-bg-orange text-white rounded mb-4 pilares">
                  <span className="font-weight-bold">Educação •</span>{" "}
                  Disseminar o conhecimento sobre as perturbações alimentares –
                  através da sensibilização social e do treino e formação de
                  terapeutas, da comunidade e de investigadores.
                </div>

                <div className="new-bg-orange text-white rounded pilares">
                  <span className="font-weight-bold">Políticas Públicas •</span>{" "}
                  Contribuir para informar o desenvolvimento de políticas
                  públicas e sociais, através da tradução de descobertas
                  científicas pioneiras em soluções que podem melhorar a
                  qualidade de vida das pessoas com perturbações do
                  comportamento alimentar.
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="new-bg-red perturbações"
          style={{ position: "relative" }}
          ref={perturbaçõesRef}
        >
          <img
            className="top-left"
            src={require("assets/img/shapes/shape-white-1-new.png")}
            alt="shape-white"
          />

          <img
            className="right-bottom"
            src={require("assets/img/shapes/shape-white-2-new.png")}
            alt="shape-white"
          />
          <div className="text-white">
            <p className="text-header-stroke text-stroke-white text-uppercase text-center m-b-0">
              O que são as Perturbações Alimentares?
            </p>

            <span className="mt-4">
              As Perturbações Alimentares são caracterizadas por alterações
              persistentes no padrão alimentar ou no comportamento associado à
              alimentação, comprometendo de forma significativa a saúde física e
              o funcionamento psicossocial. Para além disso, este tipo de
              perturbações carateriza-se pela preocupação excessiva com o peso,
              forma corporal e com a alimentação, resultando em comportamentos
              disfuncionais como forma de influenciar o peso e a forma corporal.
            </span>

            <span className="mt-4">
              Qualquer pessoa pode desenvolver uma perturbação alimentar. Esta
              perturbação pode ocorrer em meninas e meninos, mulheres e homens,
              jovens e idosos, ricos e pobres, e em pessoas de todas as
              culturas. Contudo, a adolescência e o início da idade adulta são
              os períodos críticos para o desenvolvimento de uma perturbação
              alimentar.
            </span>
          </div>
        </section>

        <section>
          <div className="px-5">
            <CustomSlider slideItems={singleSlideItems} slidesToShow={1} />
          </div>
        </section>

        <section
          className="sintomas"
          style={{ background: "#434343", color: "#fff", position: "relative" }}
        >
          <img
            className="top-left"
            src={require("assets/img/shapes/shape-white-1-new.png")}
            alt="shape-white"
          />
          <img
            className="right-bottom"
            src={require("assets/img/shapes/shape-white-2-new.png")}
            alt="shape-white"
          />
          <h3
            className="text-left"
            style={{ fontWeight: "normal", color: "#fff" }}
          >
            Apesar de não ser necessária a presença de todos os sintomas numa
            perturbação alimentar, os sintomas mais comuns são:{" "}
          </h3>

          <Container fluid>
            <Row>
              <Col>
                <ul>
                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Pensar constantemente em comida
                    </span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Dietas</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Saltar refeições</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Comer sozinho/a</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Contar obsessivamente as calorias dos alimentos
                    </span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Culpa excessiva após as refeições
                    </span>
                  </li>
                </ul>
              </Col>

              <Col>
                <ul>
                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Preocupação com a perda de controlo sobre a alimentação
                    </span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Perda acentuada de peso</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Oscilações frequentes de peso</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Ausência ou irregularidade nos períodos menstruais
                    </span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Vómito autoinduzido após as refeições
                    </span>
                  </li>
                </ul>
              </Col>

              <Col>
                <ul>
                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Preocupação excessiva com a forma e o peso corporal
                    </span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Verificação corporal frequente</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Evitar olhar para o seu corpo</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">Exercício físico excessivo</span>
                  </li>

                  <li>
                    <img
                      src={require("assets/img/shapes/shape-white-1-80.png")}
                      alt="shape-white"
                      width="30"
                      height="30"
                    />
                    <span className="ml-2">
                      Evitamento de situações sociais que envolvem refeições
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="text-center">
          <h2 className="font-weight-bold text-new-red">
            Prevalência das Perturbações Alimentares em Portugal
          </h2>

          <Container className="mt-5" fluid>
            <Row className="text-black">
              <Col className="px-3 mb-4">
                <img
                  src={require("assets/img/prevalência-1.png")}
                  alt="prevalência-1"
                />

                <div>
                  <h4
                    className="font-weight-bold"
                    style={{ color: "#434343", letterSpacing: "1px" }}
                  >
                    Prevalência em jovens mulheres (13-19 anos)
                  </h4>
                  <div className="px-4">
                    <small
                      className="font-weight-500"
                      style={{ letterSpacing: "1px" }}
                    >
                      Estudo de Machado, Machado, Gonçalves & Hoek, 2007
                    </small>
                  </div>
                </div>
              </Col>

              <Col className="px-3 mb-4">
                <img
                  src={require("assets/img/prevalência-2.png")}
                  alt="prevalência-2"
                />

                <h4
                  className="font-weight-bold"
                  style={{ color: "#434343", letterSpacing: "1px" }}
                >
                  Prevalência em alunos do ensino secundário e ensino superior
                </h4>
                <div className="px-4">
                  <small
                    className="font-weight-500"
                    style={{ letterSpacing: "1px" }}
                  >
                    Estudo de Machado, Gonçalves & Hoek, 2013
                  </small>
                </div>
              </Col>

              <Col className="px-3 mb-4">
                <img
                  src={require("assets/img/prevalência-3.png")}
                  alt="prevalência-3"
                />

                <h4
                  className="font-weight-bold"
                  style={{ color: "#434343", letterSpacing: "1px" }}
                >
                  Prevalência em mulheres com idade igual ou superior a 65 anos
                </h4>
                <div className="px-4">
                  <small
                    className="font-weight-500"
                    style={{ letterSpacing: "1px" }}
                  >
                    Estudo de Conceição et al. (2017)
                  </small>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="new-bg-red text-center"
          style={{ color: "#fff", position: "relative" }}
        >
          <img
            className="top-left"
            src={require("assets/img/shapes/shape-white-1-new.png")}
            alt="shape-white"
          />
          <img
            className="right-bottom"
            src={require("assets/img/shapes/shape-white-2-new.png")}
            alt="shape-white"
          />
          <h2 className="font-weight-bold text-white">
            Tipos de Perturbações Alimentares
          </h2>

          <span className="mt-4">
            As Perturbações Alimentares são caracterizadas por alterações
            persistentes no padrão alimentar ou no comportamento associado à
            alimentação, comprometendo de forma significativa a saúde física e o
            funcionamento psicossocial. Para além disso, este tipo de
            perturbações carateriza-se pela preocupação excessiva com o peso,
            forma corporal e com a alimentação, resultando em comportamentos
            disfuncionais como forma de influenciar o peso e a forma corporal.
          </span>

          <div className="mt-5 px-5">
            <CustomSlider
              slideItems={perturbaçõesSlideItems}
              slidesToShow={3}
            />
          </div>
        </section>

        <section className="text-center">
          <h2 className="font-weight-bold text-center text-new-red">
            Orientações para o Tratamento Psicológico das Perturbações
            Alimentares
          </h2>

          <span className="mt-4 px-5 font-weight-500">
            Para pessoas com Perturbações Alimentares poderá ser necessário um
            acompanhamento multidisciplinar (e.g. médico/a de família,
            psicólogo/a, nutricionista, psiquiatra), que possa ajudá-lo/a
            durante todo o tratamento.
          </span>

          <span className="mt-4 d-block px-5 font-weight-500">
            A duração de um tratamento psicológico para as perturbações
            alimentares varia, em média, entre 20 a 40 sessões realizadas ao
            longo de um período de tempo que irá depender das características de
            cada caso clínico, à exceção da Anorexia Nervosa, cujo tratamento
            poderá prolongar-se.
          </span>

          <button
            className="new-bg-red px-5 py-2 mt-4 text-white rounded font-weight-bold"
            style={{ letterSpacing: "1.07px", cursor: "pointer" }}
            onClick={() => {
              history.push("/questionario")
              window.scroll(0, 0)
            }}
          >
            Avaliar o meu risco
          </button>
        </section>

        <section
          className="text-center text-white unidades"
          style={{ background: "#434343", position: "relative" }}
          id="unidades"
        >
          <img
            className="top-left"
            src={require("assets/img/shapes/shape-white-1-new.png")}
            alt="shape-white"
          />
          <img
            className="right-bottom"
            src={require("assets/img/shapes/shape-white-2-new.png")}
            alt="shape-white"
          />
          <h2 className="font-weight-bold text-center text-white">
            Unidades de Tratamento em Portugal
          </h2>

          <span className="mt-4 font-weight-500">
            Em Portugal, o tratamento especializado nas Perturbações Alimentares
            ocorre, essencialmente, em três centros de tratamento
            especializados. As equipas que integram o tratamento são de caráter
            multidisciplinar, ou seja, disponibilizam acompanhamento em diversas
            vertentes, nomeadamente, na área da Psiquiatria, Psicologia,
            Nutrição e Enfermagem. Paralelamente, o internamento pode ser um
            outro tipo de tratamento também prestado nestas unidades
            hospitalares.
          </span>

          <span className="mt-4 d-block font-weight-500">
            Se precisar de auxílio para encontrar recursos na sua área de
            residência por favor contacte-nos através do formulário
          </span>

          <Link
            to={{
              pathname: "/",
              state: {
                hash: "newsletter"
              }
            }}
          >
            <button
              className="new-bg-orange px-5 py-2 mt-4 text-white rounded font-weight-bold"
              style={{ letterSpacing: "1.07px", cursor: "pointer" }}
            >
              Saiba +
            </button>
          </Link>
        </section>

        <section className="references">
          <div className="d-flex align-items-center justify-content-between">
            <h2 className="font-weight-bold">Referências</h2>

            <ChevronDown size="35" />
          </div>

          <div className="line"></div>

          <div className="mt-4">
            <ul>
              <li>
                <span className="font-weight-bold">
                  Conceição, E. M., Gomes, F. V. S. S., Vaz, A. R.,
                  Pinto-Bastos, A., & Machado, P. P. P. P.
                </span>{" "}
                (2017). Prevalence of eating disorders and picking/nibbling in
                elderly women. International Journal of Eating Disorders, 50(7).
                https://doi.org/10.1002/eat.22700
              </li>

              <li className="mt-3">
                <span className="font-weight-bold">
                  Machado, P. P., Gonçalves, S., & Hoek, H. W.
                </span>{" "}
                (2013). DSM-5 reduces the proportion of EDNOS cases: Evidence
                from community samples. International Journal of Eating
                Disorders, 46(1), 60-65. https://doi.org/10.1002/eat.22040
              </li>

              <li className="mt-3">
                <span className="font-weight-bold">
                  Machado, P. P., Machado, B., Gonçalves, S., & Hoek, H. W.
                </span>{" "}
                (2007). The prevalence of eating disorders not otherwise
                specified. International Journal of Eating Disorders, 40(3),
                212-217. https://doi.org/10.1002/eat.20358
              </li>
            </ul>
          </div>
        </section>

        <footer>
          <Row>
            <Col>
              <img
                src={require("assets/img/footer/04. Logo_PRIDE_small.svg")}
                height="60"
                width="150"
                alt=""
              />
              <img
                src={require("assets/img/footer/05. Logo_GEPA.svg")}
                height="60"
                width="150"
                alt=""
              />
              <img
                src={require("assets/img/footer/06. Universidade_do_Minho.svg")}
                height="60"
                width="60"
                alt=""
              />
              <img
                style={{ marginRight: "50px" }}
                src={require("assets/img/footer/07. Escola_de_Psicologia.svg")}
                height="60"
                width="60"
                alt=""
              />
            </Col>

            <Col>
              <img
                src={require("assets/img/footer/01.FCT_V_cor.png")}
                height="60"
                width="60"
                alt=""
              />
              <img
                src={require("assets/img/footer/02.Republica_Portuguesa.svg")}
                height="60"
                width="150"
                alt=""
              />
              <img
                src={require("assets/img/footer/03. Coofinanciadores1.png")}
                height="50"
                width="200"
                alt=""
              />
              <img
                src={require("assets/img/footer/03. Coofinanciadores2.png")}
                height="50"
                width="150"
                alt=""
              />
            </Col>
          </Row>
        </footer>
      </div>
    </>
  )
}

function CustomSlider({ slideItems, slidesToShow }) {
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow,
    slidesToScroll: 1,
    beforeChange: (_prev, next) => {
      setCurrentSlide(next)
    },
    customPaging: (i) => (
      <div
        style={{
          width: "15px",
          height: "15px",
          borderRadius: "50%",
          border: `1px solid ${slidesToShow > 1 ? "#fff" : "#b3021c"}`,
          background:
            currentSlide === i && `${slidesToShow > 1 ? "#fff" : "#b3021c"}`
        }}
      />
    ),
    responsive: [
      {
        breakpoint: 1241,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],

    nextArrow: <ArrowRight slidesToShow={slidesToShow} />,
    prevArrow: <ArrowLeft slidesToShow={slidesToShow} />
  }

  return (
    <Slider
      className={`slider ${slidesToShow > 1 ? "text-left" : "text-center"}`}
      {...settings}
    >
      {slideItems.map((item, i) => (
        <div className={`slide ${slidesToShow > 1 && "h-28"}`}>
          <div
            className={`slideItem ${slidesToShow > 1 ? "w-95" : "w-100"}`}
            key={i}
          >
            <h5>{item.title}</h5>

            {slidesToShow > 1 && <small>{item.text}</small>}

            {slidesToShow > 1 && (
              <button>
                <Download />
                <span>Descarregar</span>
              </button>
            )}
          </div>
        </div>
      ))}
    </Slider>
  )
}

function ArrowRight(props) {
  const { onClick, slidesToShow } = props

  return (
    <button
      className="arrow-right-position"
      style={{
        display: "block",
        position: "absolute",
        top: "50%",
        background: "transparent"
      }}
    >
      <ChevronRight
        size="50"
        color={slidesToShow > 1 ? "#fff" : "#b3021c"}
        strokeWidth="2"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
    </button>
  )
}

function ArrowLeft(props) {
  const { onClick, slidesToShow } = props
  return (
    <button
      style={{
        display: "block",
        position: "absolute",
        top: "50%",
        left: "-50px",
        background: "transparent"
      }}
    >
      <ChevronLeft
        size="50"
        color={slidesToShow > 1 ? "#fff" : "#b3021c"}
        strokeWidth="2"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      />
    </button>
  )
}
